body {
  margin: 0;

  font-family: 'Montserrat', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: inherit;
}

ul {
  margin: 0;
  padding: 0;

  list-style: none;
}

p {
  margin: 0;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 20px;
}

.Toastify__toast.Toastify__toast-theme--light {
  background-color: var(--color-neutral-light-50);
}

.Toastify__toast .Toastify__toast-icon {
  width: 15px;
  margin-inline-end: 8px;
}
