.box:first-child {
  padding-top: 19px;
}

.box:not(:last-child) {
  margin-bottom: 6px;
}

.title {
  margin: 0;
  margin-bottom: 19px;
  padding-bottom: 4px;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  border-bottom: 1px solid var(--border-color);
}
