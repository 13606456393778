.box {
  margin-top: 6px;
}

.link {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--link-color);
  text-decoration: underline;
}

.link:hover,
.link:focus {
  text-decoration: none;
}

.text {
  width: 100%;
  padding-right: 10px;

  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: var(--description-color);
  word-break: break-word;
}
