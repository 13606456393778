.footer {
  padding: 23px 0;

  color: var(--color-neutral-light-50);

  background-color: var(--color-neutral-dark-900);

  /*it's done for scrollbar disappearing when modal is open. we need to stretch background*/
  box-shadow: 18px 0 0 var(--color-neutral-dark-900);
}

.box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.copyright {
  margin: 0;
}

.copyright-link {
  text-decoration: underline;
}

@media (max-width: 500px) {
  .copyright-extra-text {
    display: none;
  }
}

.social {
  display: flex;
  gap: 16px;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  background-color: rgba(51, 55, 59, 1);
  border-radius: 50%;

  transition: 200ms opacity;
}

.link:hover,
.link:focus {
  opacity: 0.7;
}
