.link {
  cursor: pointer;

  display: flex;
  align-items: center;

  height: 36px;
  padding: 6px 28px;

  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--color-neutral-light-50);
  text-align: center;
  text-decoration: none;

  background-color: var(--color-accent-500);
  border: none;
  border-radius: 2rem;
  box-shadow: 0 4px 18px rgb(245 26 81 / 35%), inset 0 1px 0 rgb(255 255 255 / 30%);

  transition: var(--transtition-duration-basic) background-color,
  var(--transtition-duration-basic) color;
}

.link:focus-visible {
  outline: 2px solid var(--color-outline-focus);
}

.link:disabled {
  cursor: initial;

  color: var(--color-button-text-disabled);

  background-color: var(--color-button-disabled);
}

.link:hover {
  background-color: var(--color-accent-400);
}

.link:active {
  background-color: var(--color-accent-600);
}
