.text + .text {
  margin-top: 20px;
}

.text:last-of-type {
  margin-bottom: 43px;
}

.button-box {
  display: flex;
  justify-content: end;
}
