.box:global(.container) {
  margin-bottom: 36px;
}

@media (max-width: 1100px) {
  .box:global(.container) {
    margin-bottom: 10px;
  }
}


@media (max-width: 800px) {
  .link {
    min-height: 100px;
  }
}

.img {
  width: 100%;
  height: auto;

  object-fit: contain;
}

@media (max-width: 600px) {
  .img {
    max-width: 450px;
  }

  .link {
    display: block;

    max-width: 320px;
    margin: 0 auto;
  }
}