.box {
  padding-right: 24px;
  padding-left: 24px;
}

.search {
  width: 100%;

  line-height: 18px;
}
