.box {
  display: grid;
  grid-template-rows: min-content 1fr;

  padding: 24px 0 0 0;

  background-color: var(--color-light-mode-100);
  border: 1px solid var(--border-color);
  border-radius: 2px;
}
