.box {
}

.box--isUnavailable {
  pointer-events: none;
}

.box--isUnavailable .title,
.box--isUnavailable b {
  color: var(--disabled-color);
}

.box--isDone .title-box {
  cursor: pointer;
}

.title {
  display: flex;
  gap: 12px;
  align-items: center;

  margin-left: 36px;
  padding-top: 21px;
  padding-bottom: 7px;

  font-size: 16px;
  font-weight: 500;
  line-height: 19px;

  border-bottom: 1px solid var(--border-color);
}

.titleText {
  font-weight: 600;
}

.titleText--isDone {
  color: var(--color-success-600);
}

.arrow-icon {
  transition: 200ms transform;
}

.arrow-icon svg {
  color: var(--font-color);
}

.box--open .arrow-icon {
  transform: rotate(180deg);
}
