.list {
  overflow: hidden;

  max-height: 0;

  visibility: hidden;
}

.list--open {
  max-height: 1000px;

  visibility: visible;

  transition: max-height 400ms;
}
