.box {
  padding-left: 24px;
}

.searchRow {
  margin-bottom: 10px;
  padding-right: 24px;
}

.list {
  padding-right: 24px;
}