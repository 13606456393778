.box {
  display: flex;
  flex-direction: column;
  gap: 29px;
  align-items: center
}

.text {
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}
