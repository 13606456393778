.box {
  padding: 19px 21px 17px 21px;

  border-top: 1px solid var(--border-color);
}

.text {
  font-size: 14px;
  line-height: 24px;
}
