.layout {
  --shadow: -1px -1px 4px rgba(0, 0, 0, 0.05), 1px 1px 4px rgba(0, 0, 0, 0.05);

  --bg-color: #fefefe;
  --border-color: #d9d7d8;
  --scrollbar-tumbler-color: #d9d7d8;
  --scroll-color: #ecebeb;
  --accent-color: #fee8ee;
  --success-color: #14b371;

  /*  text */
  --font-color: #0d0b0c;
  --link-color: #1a75f5;
  --description-color: #8c8889;
  --disabled-color: #8c8889;

  /* circle result */
  --inactive-result-color: #b3b0b1;
  --circle-result-bg-color: #ecebeb;
  --circle-result-bg-color-current: var(--color-neutral-light-100);

  /* background */
  --button-bg: var(--color-neutral-dark-900);

  display: grid;
  grid-template-rows: auto 1fr auto;

  width: 100%;
  height: 100vh;

  color: var(--font-color);
}

.layout--dark {
  --bg-color: #131e2b;
  --border-color: #182636;
  --accent-color: #310510;
  --scrollbar-tumbler-color: #46515e;
  --scroll-color: #182636;

  /*  text */
  --font-color: #fffdfe;
  --link-color: #4891f7;

  /*  circle result */
  --inactive-result-color: #46515e;
  --circle-result-bg-color: #46515e;
  --circle-result-bg-color-current: #46515e;

  /* background */
  --button-bg: var(--color-dark-mode-400);
}
