.header {
  margin-bottom: 24px;
  padding: 12px 0;

  background-color: var(--color-neutral-light-50);
  border-bottom: 1px solid var(--border-color);
}

@media (max-width: 800px) {
  .header {
    margin-bottom: 10px;
  }
}

.headerBox {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 800px) {
  .headerBox {
    justify-content: center;
  }
}

.infoButton {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border: none;
}

.infoButton:focus {
  outline: none;
}

.infoButton:focus-visible {
  border-radius: 50%;
  outline: 1px solid #ababc1;
  outline-offset: 3px;
}

.infoButton svg {
  fill: var(--button-bg);
}

.buttonBox {
  display: flex;
  gap: 36px;
  align-items: center;
}

@media (max-width: 800px) {
  .buttonBox {
    gap: 10px;
  }
}
