.box {
  cursor: pointer;

  position: relative;
}

.box.loading {
  cursor: auto;
}

.box--isUnavailable {
  pointer-events: none;
}

.box--isUnavailable .text {
  color: var(--disabled-color);
}

.box::before {
  content: '';

  position: absolute;
  z-index: 0;

  width: 0;
  height: 100%;

  transition: 300ms background-color;
}

.box:hover:not(.loading)::before,
.box:focus:not(.loading)::before {
  width: 100%;
  height: calc(100% - 1px);

  background-color: var(--accent-color);
}

.box.current {
  background-color: var(--accent-color);
}

.content {
  position: relative;

  display: flex;
  gap: 12px;
  align-items: center;

  min-height: 47px;
  margin-left: 48px;
  padding: 5px 15px 5px 0;

  border-bottom: 1px solid var(--border-color);
}

.text {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}

.inactive-result-text {
  color: var(--inactive-result-color);

  transition: 200ms color;
}

.box:hover .inactive-result-text,
.box:focus .inactive-result-text {
  color: var(--font-color);
}

.succeed-check {
  margin: 0 6px;
}

.link {
  color: var(--color-text-primary);
  text-decoration: none;
}

.link.loading {
  pointer-events: none;
}
