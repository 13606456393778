.list {
  overflow: hidden;

  max-height: 0;

  visibility: hidden;
}

.list--open {
  max-height: 2000px;

  visibility: visible;

  transition: max-height 700ms;
}
