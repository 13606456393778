.grid {
  display: grid;
  grid-template-columns: 372px 1fr;
  grid-template-rows: 612px;
  gap: 48px;

  margin-bottom: 20px;
}

@media (max-width: 800px) {
  .grid {
    grid-template-columns: auto;
  }
}
