.box {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
}

.progress {
  position: relative;
  z-index: 1;

  color: var(--success-color) !important;
}

.progress-background {
  position: absolute;
  top: 0;
  left: 0;

  color: var(--circle-result-bg-color) !important;
}

.progress-background.current {
  color: var(--circle-result-bg-color-current) !important;
}

.text {
  position: absolute;
  z-index: 2;

  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}

.inactive {
  color: var(--circle-result-bg-color);
}
