.box {
  padding: 20px 20px 17px 20px;

  border-bottom: 1px solid var(--border-color);
}

.title {
  margin: 0;
  margin-bottom: 11px;

  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
}

.description {
  font-size: 14px;
  line-height: 24px;
  word-break: break-word;
}
