.box {
}

.box--isUnavailable {
  pointer-events: none;
}

.box--isUnavailable .title {
  color: var(--disabled-color);
}

.box:first-child .title {
  padding-top: 7px;
}

.box--isDone .title-box {
  cursor: pointer;
}

.title {
  display: flex;
  gap: 12px;
  align-items: center;

  margin-left: 24px;
  padding-top: 19px;
  padding-bottom: 6px;

  font-size: 18px;
  font-weight: 500;
  line-height: 21px;

  border-bottom: 1px solid var(--border-color);
}

.titleText {
  font-weight: 600;
}

.titleText--isDone {
  color: var(--color-success-600);
}

.arrow-icon {
  transition: 200ms transform;
}

.arrow-icon svg {
  color: var(--font-color);
}

.box--open .arrow-icon {
  transform: rotate(180deg);
}
